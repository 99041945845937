/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Alert,
  Col,
  Container,
  DisplayDataGrid,
  DisplayDataItem,
  FAB,
  FabSpeedDial,
  Icon,
  Loading,
  Panel,
  Row,
  SectionTitle,
  Table,
  formatUtils,
  useAuth
} from '@elotech/components';
import { ClienteService } from 'common/service';
import { Cliente, CountUsuarioEntidade, DashboardInfo } from 'common/type';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import ModulosPicker from '../../components/modulo-picker/ModulosPicker';
import { Roles } from '../../roles/RolesConsole';
import { UsuarioExternoService } from '../../service';

const ClienteViewPage = () => {
  const { hasRole } = useAuth();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [cliente, setCliente] = useState<Cliente | undefined>(undefined);
  const [dashboardInfo, setDashboardInfo] = useState<DashboardInfo>();
  const [usuariosExternosCount, setUsuariosExternosCount] = useState(0);
  const [
    loadingUsuariosExternosCount,
    setLoadingUsuariosExternosCount
  ] = useState(false);

  useEffect(() => {
    setLoading(true);
    ClienteService.findById(id)
      .then(response => setCliente(response.data))
      .catch(error =>
        Alert.error({ title: `Erro ao buscar o cliente ${id}` }, error)
      )
      .finally(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    if (cliente) {
      setLoading(true);
      ClienteService.dashboardInfo(cliente?.tenant)
        .then(response => setDashboardInfo(response.data))
        .catch(error =>
          Alert.error({ title: `Erro ao buscar informações` }, error)
        )
        .finally(() => setLoading(false));
    }
  }, [cliente]);

  useEffect(() => {
    if (cliente) {
      setLoadingUsuariosExternosCount(true);
      UsuarioExternoService.count(cliente?.tenant)
        .then(count => setUsuariosExternosCount(count))
        .catch(error =>
          Alert.error({ title: `Erro ao buscar informações` }, error)
        )
        .finally(() => setLoadingUsuariosExternosCount(false));
    }
  }, [cliente]);

  const getActionsButtons = () => {
    return (
      <FabSpeedDial icon="ellipsis-v" title="Ações">
        {hasRole(Roles.clientes_modulo_config.name) ? (
          <FAB
            icon="cog"
            onClick={() =>
              history.push(`/clientes/${cliente?.id}/modulo-config`)
            }
            title="Configuração dos Módulos"
            disabled={false}
          />
        ) : (
          undefined
        )}
        {hasRole(Roles.manage_cliente.name) ? (
          <FAB
            icon="pencil-alt"
            onClick={() => history.push(`/clientes/${cliente?.id}`)}
            title="Editar"
            disabled={false}
          />
        ) : (
          undefined
        )}
      </FabSpeedDial>
    );
  };

  return (
    <>
      <Container breadcrumb>
        <Loading loading={loading || loadingUsuariosExternosCount} />
        {cliente && (
          <>
            <DisplayDataGrid column>
              <Row>
                <DisplayDataItem title="Nome" md={6}>
                  {cliente.nome}
                </DisplayDataItem>
                <DisplayDataItem title="Identificador" md={2}>
                  <a
                    href={
                      window.location.origin?.replace(
                        'console',
                        cliente.tenant
                      ) || '#'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {cliente.tenant} &nbsp;
                  </a>
                  <Icon
                    icon="external-link-alt"
                    darkPrimary
                    hoverColor="white"
                  />
                </DisplayDataItem>
                <DisplayDataItem title="Contratante" md={1}>
                  {cliente.contratante}
                </DisplayDataItem>
                <DisplayDataItem title="UF" md={1}>
                  {cliente.uf}
                </DisplayDataItem>
                <DisplayDataItem title="Ativo" md={1}>
                  {cliente.ativo ? 'Sim' : 'Não'}
                </DisplayDataItem>
                <DisplayDataItem title="Multi realm" md={1}>
                  {cliente.multiRealm ? 'Sim' : 'Não'}
                </DisplayDataItem>
              </Row>
              <Row>
                <DisplayDataItem title="Quantidade de usuários" md={2}>
                  {dashboardInfo?.usuarios}
                </DisplayDataItem>
                <DisplayDataItem title="Quantidade de usuários externos" md={2}>
                  {usuariosExternosCount}
                </DisplayDataItem>
              </Row>
            </DisplayDataGrid>
            <Row>
              <Col sm={12} className="mt-xs">
                <Panel isTable>
                  <Table
                    fixed
                    reduced={false}
                    values={dashboardInfo?.usuariosEntidade || []}
                    loading={loading}
                    keyExtractor={v => v.entidade}
                  >
                    <Table.Column<CountUsuarioEntidade>
                      header="Entidade"
                      value={v => v.entidade}
                    />
                    <Table.Column<CountUsuarioEntidade>
                      header="Usuários"
                      value={v => v.usuarios}
                    />
                  </Table>
                </Panel>
              </Col>
            </Row>

            <SectionTitle>Entidades</SectionTitle>

            {cliente.entidades.map(entidade => (
              <Panel
                expansible
                title={entidade.nome}
                key={entidade.id}
                collapsed={true}
              >
                <DisplayDataGrid column>
                  <Row>
                    <DisplayDataItem title="Id" md={3}>
                      {entidade.id}
                    </DisplayDataItem>
                    <DisplayDataItem title="Ativo" md={3}>
                      {entidade.ativo ? 'Sim' : 'Não'}
                    </DisplayDataItem>
                    <DisplayDataItem title="Código" md={1}>
                      {entidade.codigo}
                    </DisplayDataItem>
                    <DisplayDataItem title="Identificador" md={3}>
                      {formatUtils.formatCpfCnpj(entidade.cnpj)}
                    </DisplayDataItem>
                    <DisplayDataItem title="Nome" md={8}>
                      {entidade.nome}
                    </DisplayDataItem>
                  </Row>
                  <Row>
                    <DisplayDataItem title="CEP" md={2}>
                      {entidade.endereco?.cep}
                    </DisplayDataItem>
                    <DisplayDataItem title="Logradouro" md={4}>
                      {entidade.endereco?.logradouro}
                    </DisplayDataItem>
                    <DisplayDataItem title="Complemento" md={4}>
                      {entidade.endereco?.complemento}
                    </DisplayDataItem>
                    <DisplayDataItem title="Número" md={2}>
                      {entidade.endereco?.numero}
                    </DisplayDataItem>
                  </Row>
                  <Row>
                    <DisplayDataItem title="Bairro" md={4}>
                      {entidade.endereco?.bairro}
                    </DisplayDataItem>
                    <DisplayDataItem title="Cidade" md={4}>
                      {entidade.endereco?.cidade}
                    </DisplayDataItem>
                    <DisplayDataItem title="UF" md={2}>
                      {entidade.endereco?.uf}
                    </DisplayDataItem>
                    <DisplayDataItem title="IBGE" md={2}>
                      {entidade.endereco?.ibge}
                    </DisplayDataItem>
                  </Row>
                </DisplayDataGrid>
                <ModulosPicker
                  modulos={entidade.modulos?.map(e => e.modulo)}
                  readonly
                ></ModulosPicker>
              </Panel>
            ))}
          </>
        )}
        <div className="btn-save">{getActionsButtons()}</div>
      </Container>
    </>
  );
};

export default ClienteViewPage;

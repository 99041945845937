import {
  ActionButton,
  ActionsGroup,
  Alert,
  Col,
  Container,
  DisplayDataGrid,
  DisplayDataItem,
  FAB,
  Loading,
  ObjectUtils,
  PageRequest,
  Panel,
  Row,
  SearchPagination,
  SearchPanel,
  Sort,
  Table,
  TableChild,
  usePagedQuery,
  usePendingRequests
} from '@elotech/components';
import { ClienteService } from 'common/service';
import { Cliente } from 'common/type';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { ModuloExternoService } from '../../service';
import { ModuloExterno } from '../../type';

type Props = {};

const buildFilter = (filter: string) => `descricao=='*${filter}*'`;

const AtributosTable: React.FC<{ item: ModuloExterno }> = ({ item }) => {
  const attributeEntries = useMemo(() => Object.entries(item.atributos), [
    item
  ]);

  return (
    <TableChild>
      <Panel isTable>
        <Table values={attributeEntries} keyExtractor={item => `${item[0]}`}>
          <Table.Column<[string, string]>
            header="Chave"
            value={item => item[0]}
          />
          <Table.Column<[string, string]>
            header="Valor"
            value={item => item[1]}
          />
        </Table>
      </Panel>
    </TableChild>
  );
};

const ModuloExternoListPage: React.FC<Props> = () => {
  const { wrap, hasPendingRequests } = usePendingRequests();
  const [cliente, setCliente] = useState<Cliente>();
  const [filter, setFilter] = useState<string>('');
  const [atributosVisiveis, setAtributosVisiveis] = useState<
    Record<string, boolean>
  >({});

  const searchFunction = useCallback(
    (search: string, page?: PageRequest, sort?: Sort) =>
      ModuloExternoService.findAll(cliente?.id!, search, page, sort),
    [cliente]
  );
  const onError = useCallback(
    (error: unknown) => {
      Alert.error(
        {
          title: `Erro ao buscar os módulos externos do cliente ${cliente?.nome}`
        },
        error
      );
    },
    [cliente]
  );
  const {
    values,
    loading,
    pagination,
    doSearch,
    doSortChange,
    doPagedSearch
  } = usePagedQuery<ModuloExterno>({
    search: searchFunction,
    onError: onError
  });

  const { idCliente } = useParams<{ idCliente: string }>();
  useEffect(() => {
    wrap(() =>
      ClienteService.findById(idCliente).then(response =>
        setCliente(response.data)
      )
    );
  }, [idCliente, wrap]);

  useEffect(() => {
    cliente && doSearch();
  }, [cliente, doSearch]);

  const onFilter = () => doSearch(buildFilter(filter));

  const onDelete = (moduloExterno: ModuloExterno) => {
    wrap(() =>
      ModuloExternoService.deleteById(moduloExterno.id)
        .then(() => doSearch())
        .catch(error =>
          Alert.error(
            {
              title: `Erro ao deletar módulo ${moduloExterno.descricao}`
            },
            error
          )
        )
    );
  };

  return (
    <Container breadcrumb>
      <Loading loading={hasPendingRequests} />
      {cliente && (
        <>
          <DisplayDataGrid column>
            <Row>
              <DisplayDataItem title="Nome" md={9}>
                {cliente.nome}
              </DisplayDataItem>
              <DisplayDataItem title="Tenant" md={3}>
                {cliente.tenant}
              </DisplayDataItem>
            </Row>
          </DisplayDataGrid>
          <Panel isTable className={'mt-xs'}>
            <SearchPanel>
              <Row className="mb-xs">
                <Col className="form-group">
                  <input
                    autoFocus
                    value={filter}
                    name="searchValue"
                    placeholder="Digite o filtro"
                    onChange={e => setFilter(e.target.value)}
                    style={{ width: `calc(100% - 82px)`, height: '28px' }}
                    onKeyDown={e => e.key === 'Enter' && onFilter()}
                  />
                  <button
                    data-test-id="buttonAdicionarFiltro"
                    className="btn filter"
                    type="button"
                    onClick={onFilter}
                  >
                    Filtrar
                  </button>
                </Col>
              </Row>
            </SearchPanel>
            <Table
              values={values}
              loading={loading}
              sortOnHeaderColumnClick={doSortChange}
              renderInnerComponent={item => {
                return (
                  atributosVisiveis[item.id] && <AtributosTable item={item} />
                );
              }}
            >
              <Table.Column<ModuloExterno>
                header={'Descrição'}
                name="descricao"
                sortable
                value={value => value.descricao}
              />
              <Table.Column<ModuloExterno>
                header={'Ativo'}
                name="ativo"
                sortable
                value={value => ObjectUtils.booleanToSimNao(value.ativo)}
              />
              <Table.Column<ModuloExterno>
                header={'Tipo'}
                name="tipo"
                sortable
                value={value => value.tipo}
              />
              <Table.Column<ModuloExterno>
                header={'Área'}
                name="area"
                sortable
                value={value => value.area}
              />
              <Table.Column<ModuloExterno>
                header={''}
                value={value => (
                  <ActionsGroup>
                    <ActionButton
                      icon="eye"
                      label="Visualizar Atributos"
                      onClick={() =>
                        setAtributosVisiveis(old => ({
                          ...old,
                          [value.id]: !old[value.id]
                        }))
                      }
                    />
                    <ActionButton
                      icon="pencil-alt"
                      label="Editar"
                      path={`/clientes/${cliente.id}/modulo-externo/${value.id}`}
                    />
                    <ActionButton
                      icon="trash"
                      label="Remover"
                      onClick={() => onDelete(value)}
                    />
                  </ActionsGroup>
                )}
              />
            </Table>
            {pagination && (
              <SearchPagination
                page={pagination}
                searchWithPage={doPagedSearch}
              />
            )}
          </Panel>
          <div className="btn-save">
            <FAB
              icon="plus"
              path={`/clientes/${cliente.id}/modulo-externo/new`}
              title="Novo"
            />
          </div>
        </>
      )}
    </Container>
  );
};

export default ModuloExternoListPage;

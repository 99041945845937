import { PageRequest, PagedResponse, Sort } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';
import { EnumDTO } from 'common/type';

import { ModuloExterno } from '../type';

const BASE_URL = '/console-api/api/modulos-externos';

export const findAll = (
  idCliente: string,
  search: string = '',
  page?: PageRequest,
  sort?: Sort
): AxiosPromise<PagedResponse<ModuloExterno>> => {
  const mergedSearch = [`cliente.id=='${idCliente}'`, search]
    .filter(Boolean)
    .join(' and ');
  return axios.get(BASE_URL, {
    params: {
      ...page,
      ...sort,
      search: mergedSearch
    }
  });
};

export const findById = (id: string): AxiosPromise<ModuloExterno> =>
  axios.get(`${BASE_URL}/${id}`);

export const getAreas = (): AxiosPromise<EnumDTO[]> =>
  axios.get(`${BASE_URL}/areas`);

export const deleteById = (id: string): AxiosPromise<void> =>
  axios.delete(`${BASE_URL}/${id}`);

export const save = (
  moduloExterno: ModuloExterno
): AxiosPromise<ModuloExterno> =>
  moduloExterno.id
    ? axios.put(`${BASE_URL}/${moduloExterno.id}`, moduloExterno)
    : axios.post(`${BASE_URL}`, moduloExterno);

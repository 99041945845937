import {
  ActionButton,
  ActionsGroup,
  ArrayUtils,
  Button,
  ObjectUtils,
  Panel,
  SectionTitle,
  Table,
  formatUtils
} from '@elotech/components';
import { Entidade, initEntidade } from 'common/type';
import React, { useState } from 'react';

import EntidadeForm from './EntidadeForm';

type Props = {
  entidades: Entidade[];
  onChange: (values: Entidade[]) => void;
};

const EntidadeList: React.FC<Props> = ({ entidades, onChange }) => {
  const [showForm, setShowForm] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number | undefined>(
    undefined
  );
  const [show, setShow] = useState(false);

  const toggleButton = () => {
    setShow(!show);
  };

  const onRemove = (index: number) => {
    const newValues = [
      ...entidades.slice(0, index),
      ...entidades.slice(index + 1)
    ];
    onChange(newValues);
  };

  const onEdit = (index: number) => {
    setShowForm(true);
    setEditingIndex(index);
  };

  const onNew = () => {
    setShowForm(true);
    setEditingIndex(undefined);
  };

  const clearForm = () => {
    setShowForm(false);
    setEditingIndex(undefined);
    toggleButton();
  };

  const onSubmitEntidade = (value: Entidade) => {
    value.endereco.cep = value.endereco.cep.replace('-', '');
    const newValues = ArrayUtils.addOrReplace(
      entidades,
      value,
      (_, index) => index === editingIndex
    );
    onChange(newValues);
    clearForm();
    toggleButton();
  };

  return (
    <>
      <SectionTitle hasButton>
        Entidades
        {!showForm && (
          <Button onClick={onNew} iconPosition="left">
            <i className="fa fa-plus" />
            Nova
          </Button>
        )}
      </SectionTitle>
      {showForm && (
        <EntidadeForm
          initialValue={entidades[editingIndex ?? -1] ?? initEntidade}
          onSubmit={onSubmitEntidade}
          onCancel={clearForm}
        />
      )}
      <Panel isTable>
        <Table values={entidades}>
          <Table.Column<Entidade> header="ID" value={value => value.id} />
          <Table.Column<Entidade>
            header="Codigo"
            value={value => value.codigo}
          />
          <Table.Column<Entidade> header="Nome" value={value => value.nome} />
          <Table.Column<Entidade>
            header="CNPJ"
            value={value => formatUtils.formatCpfCnpj(value.cnpj)}
          />
          <Table.Column<Entidade>
            header="Ativo"
            value={value => ObjectUtils.booleanToSimNao(value.ativo)}
          />
          <Table.Column<Entidade>
            header=""
            value={(value, index) => (
              <ActionsGroup>
                {!show && (
                  <ActionButton
                    key="edit-button"
                    icon="pencil-alt"
                    label="Editar"
                    onClick={() => {
                      onEdit(index);
                      toggleButton();
                    }}
                  />
                )}
                <ActionButton
                  key="remove-button"
                  icon="trash-alt"
                  label="Remover"
                  onClick={() => onRemove(index)}
                />
              </ActionsGroup>
            )}
          />
        </Table>
      </Panel>
    </>
  );
};

export default EntidadeList;

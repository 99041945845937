import {
  Alert,
  BasicInput,
  Container,
  DisplayDataGrid,
  DisplayDataItem,
  FAB,
  FormikCheckBox,
  FormikSelect,
  Loading,
  Panel,
  Row,
  Yup,
  usePendingRequests
} from '@elotech/components';
import { ClienteService } from 'common/service';
import { Cliente, EnumDTO } from 'common/type';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';

import { ModuloExternoService } from '../../service';
import { ModuloExterno } from '../../type';

type Props = {};

const defaultValue = {
  ativo: true,
  descricao: '',
  area: '',
  tipo: '',
  atributos: {}
} as ModuloExterno;

const TipoModuloExternoOptions = [
  { label: 'Link', value: 'LINK' },
  { label: 'Web Iss', value: 'WEB_ISS' }
];

const validationSchema = Yup.object().shape({
  ativo: Yup.boolean().label('Ativo'),
  descricao: Yup.string()
    .label('Descrição')
    .required()
    .max(100),
  area: Yup.string()
    .label('Área')
    .required(),
  tipo: Yup.string()
    .label('Tipo')
    .required(),
  atributos: Yup.object()
    .when('tipo', {
      is: 'LINK',
      then: Yup.object().shape({
        URL: Yup.string().required('Url é obrigatória quando o tipo for Link')
      })
    })
    .when('tipo', {
      is: 'WEB_ISS',
      then: Yup.object().shape({
        URL_WEB_ISS: Yup.string().required(
          'Url é obrigatória quando o tipo for Web Iss'
        ),
        SECRET_WEB_ISS: Yup.string().required(
          'Secret é obrigatório quando o tipo for Web Iss'
        )
      })
    })
});
const ModuloExternoFormPage: React.FC<Props> = props => {
  const { idCliente, idModulo } = useParams<{
    idCliente: string;
    idModulo: string;
  }>();
  const { wrap, hasPendingRequests } = usePendingRequests();
  const [moduloExterno, setModuloExterno] = React.useState<ModuloExterno>();
  const [cliente, setCliente] = React.useState<Cliente>();
  const [areas, setAreas] = React.useState<EnumDTO[]>([]);
  const history = useHistory();

  useEffect(() => {
    if (idModulo === 'new') {
      setModuloExterno(defaultValue);
      return;
    }
    wrap(() =>
      ModuloExternoService.findById(idModulo)
        .then(response => setModuloExterno(response.data))
        .catch(error => {
          Alert.error({ title: `Erro ao buscar o modulo ${idModulo}`, error });
        })
    );
  }, [idModulo, wrap]);

  useEffect(() => {
    wrap(() =>
      ClienteService.findById(idCliente).then(response =>
        setCliente(response.data)
      )
    );
  }, [idCliente, wrap]);

  useEffect(() => {
    wrap(() =>
      ModuloExternoService.getAreas().then(response => setAreas(response.data))
    );
  }, [wrap]);

  const onSubmit = (value: ModuloExterno) => {
    if (idModulo === 'new' && cliente) {
      value.cliente = cliente;
    }
    wrap(() =>
      ModuloExternoService.save(value)
        .then(() => history.goBack())
        .catch(error => {
          Alert.error(
            {
              title: 'Erro ao salvar o módulo externo'
            },
            error
          );
        })
    );
  };
  return (
    <Container breadcrumb status={idModulo === 'new' ? 'new' : 'edit'}>
      <Loading loading={hasPendingRequests} />
      {cliente && (
        <DisplayDataGrid column>
          <Row>
            <DisplayDataItem title="Nome" md={9}>
              {cliente.nome}
            </DisplayDataItem>
            <DisplayDataItem title="Tenant" md={3}>
              {cliente.tenant}
            </DisplayDataItem>
          </Row>
        </DisplayDataGrid>
      )}
      {moduloExterno && (
        <Formik
          initialValues={moduloExterno}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {formProps => (
            <Panel className={'mt-xs'}>
              <Row>
                <BasicInput name="descricao" label={'Descrição'} size={4} />
                <FormikCheckBox name="ativo" label="Ativo" noLabel={false} />
                <FormikSelect
                  name={'tipo'}
                  label={'Tipo'}
                  options={TipoModuloExternoOptions}
                  getOptionLabel={option => option.label}
                  getOptionValue={option => option.value}
                />
                <FormikSelect
                  name={'area'}
                  label={'Área'}
                  options={areas}
                  getOptionLabel={option => option.descricao}
                  getOptionValue={option => option.valor}
                  fast={false}
                />
              </Row>

              {formProps.values.tipo === 'WEB_ISS' && (
                <Row>
                  <BasicInput
                    name={'atributos.URL_WEB_ISS'}
                    label={'Url Web Iss'}
                    size={6}
                  />
                  <BasicInput
                    name={'atributos.SECRET_WEB_ISS'}
                    label={'Secret Web Iss'}
                    size={4}
                  />
                </Row>
              )}

              {formProps.values.tipo === 'LINK' && (
                <Row>
                  <BasicInput name={'atributos.URL'} label={'Url'} size={6} />
                </Row>
              )}
              <div className="btn-save">
                <FAB
                  data-test-id="btn-save"
                  icon="check"
                  onClick={formProps.submitForm}
                  title="Salvar"
                />
              </div>
            </Panel>
          )}
        </Formik>
      )}
    </Container>
  );
};

export default ModuloExternoFormPage;
